@import "../variables";


.module_cards {
    border: 2px solid 2px solid alpha(@color-blue-dark, 7%);
    color: inherit;
    margin-top: 60px;
    padding: 28px 37px 41px 37px;
    text-decoration: none;
    transition: background 0.3s ease-out, border 0.3s ease-out;
    width: 100%;
    align-items: stretch;
    display: flex;

    justify-content: space-between;


   .pdflink {
     margin-top: 30px;
   }
}

.module_card_columns {
   width: 65%;
}

.module-goal {
    margin-top: 20px;
}

.module_card_columns_img {
  width: 30%;

    .svg-icon-module {
        width: 256px;
        height: 256px;
    }
}

@media screen and (max-width: 600px) {
  .module_cards:nth-child(odd) {
    flex-direction: column;
  }
  .module_cards:nth-child(even) {
    flex-direction: column-reverse;
  }
.module_card_columns {
   width: 90%;
}
  .module_card_columns_img {
    width: 90%;
    padding-bottom: 20px;

    .svg-icon-module {
        width: 200px;
        height: 200px;
    }
  }
}

@media screen and (max-width: 800px) {
  .module_card_columns_img {

    .svg-icon-module {
        width: 128px;
        height: 128px;
    }
  }
}
