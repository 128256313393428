// last modified 2016-02-18

.placeholder(@text-color) {
  &:-moz-placeholder { // Firefox 4-18
    color: @text-color;
    opacity: 1;
  }
  &::-moz-placeholder { // Firefox 19+
    color: @text-color;
    opacity: 1;
  }
  &:-ms-input-placeholder { // Internet Explorer 10+
    color: @text-color;
  }
  &::-webkit-input-placeholder { // Safari and Chrome
    color: @text-color;
  }
}


.img-icon-retina(@img-path; @img-path-retina) {
  background: url(@img-path) center / contain no-repeat;
  height: image-height(@img-path);
  width: image-width(@img-path);

  @media @retina {
    background-image: url(@img-path-retina);
  }
}
