@import "../variables";
@import "../mixins";
@import "../widgets/blocks";

.section-intro-slide, .section-intro-consumer-slide, .section-intro-testcenter-slide {
  background: #2F95B1;
  min-height: 70vh;

  .intro-slide {
    &:extend(.block);
    color: #fff;
    position: relative;
    padding-bottom: 88px;
    z-index: 10;
    margin: 0 auto;
    min-width: 320px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media @tablet {
      min-height: 580px;
      padding: 46px 20px 80px 20px;
    }

    @media @desktop {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
    }

    &:after {
      background: radial-gradient(circle, rgba(91, 185, 212, 1), rgba(91, 185, 212, 0) 60%);
      //background: radial-gradient(circle, rgba(143, 216, 238, 1), rgba(143, 216, 238, 0) 60%);
      content: '';
      display: inline-block;
      @height: 800px;
      height: @height;
      position: absolute;
      right: -250px;
      top: -191px;
      width: auto;
      z-index: 1;
    }

    &__card-img {
      //.img-icon-retina("@{img-dir}dk-card-shadow.svg", "@{img-dir}dk-card-shadow.svg");
      .img-icon-retina("@{img-dir}img-card.png", "@{img-dir}img-card_2x.png");
      display: block;
      height: 100vw;
      margin: 0 auto;
      max-height: 467px;
      max-width: 415px;
      position: relative;
      width: 88.6vw;
      z-index: 2;
      margin-right: 20px;

      @media @tablet {
        position: absolute;
        top: 27px;
        right: -18px;
      }

      @media @desktop {
        top: 50%;
        margin-top: -233.5px;
      }
    }

    &__title {
      font-weight: 200;
      margin: 0 0 26px;
      position: relative;
      z-index: 20;

      @media screen and (min-width: 801px) {
          font-size: 65px;
          line-height: 1.143;
          width: 75%;
      }

      @media screen and (max-width: 800px) {
        font-size: 40px;
        line-height: 1.125;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__content {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.389;
      position: relative;
      z-index: 20;

      @media @tablet {
        font-size: 25px;
        line-height: 1.6;
        width: 60%;
      }
    }

    &__scroll-button {
      bottom: 39px;
      display: none;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      z-index: 20;

      @media @tablet {
        display: block;
      }
    }
  }
}
