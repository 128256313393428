@import "../variables";
@import "../mixins";

.what-is-slide-wrap {}
  //


.what-is-slide {

  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @media @desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 20px;
  }

  &__content {
    @media @desktop {
      position: relative;
      width: (420 / 940 * 100%); // proportions from the design
    }
  }

  &__title {
    margin-bottom: 47px;
    text-align: left;
  }

  &__text {
  }
  //

  .what-is-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    @media @tablet {
      flex-direction: row;
    }

    @media @desktop {
      flex-direction: row;
    }
  }

  .what-is-flex-item {
    width: 100%;

    @media @tablet {
      width: 45%;
    }
    @media @desktop {
      width: 45%;
    }
  }

  .what-is-flex-img {
    display: block;
    margin: 0 auto;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

