@import "../variables";
@import "../mixins";

.input {
  &::placeholder {
    color: @color-blue-dark;
    opacity: 1;
  }
  border: none;
  border-bottom: 2px solid alpha(@color-blue-dark, 12%);
  box-shadow: none;
  display: block;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding-bottom: 24px;
  padding-top: 2px;
  transition: border 0.3s ease-out;
  width: 100%;

  &_has-search-icon {
    background: url('../images/i-search.svg') right 0 / 15px 15px no-repeat;
    padding-right: 26px;
  }

  &:hover {
    border-bottom-color: alpha(@color-blue-dark, 21%);
  }

  &:focus {
    border-bottom-color: @color-blue-dark;
  }

  & + & {
    margin-top: 30px;
  }
}
