@import "../variables";
@import "../typography";

.cards {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.card {
  border: 2px solid alpha(@color-blue-dark, 7%);
  color: inherit;
  display: inline-block;
  margin-top: 20px;
  padding: 28px 37px 41px 37px;
  text-decoration: none;
  transition: background 0.3s ease-out, border 0.3s ease-out;
  width: 100%;

  &:nth-child(1) {
    margin-top: 0;
  }

  @media @tablet {
    margin-right: 20px;
    width: calc(~'50% - 20px / 2');

    &:nth-child(1), &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }


  a&:hover {
    background-color: @color-blue;
    border-color: @color-blue;
  }


  &_columns {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }


  &_dark {
    background-color: #f4f5f5;
    border-color: transparent;
  }


  &_width-third {
    @media @tablet {
      @padding-left: 25px;
      padding-left: @padding-left;
      padding-right: @padding-left;
      width: calc(~'(100% - 40px) / 3');

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin-top: 0;
      }
    }


    @media @desktop {
      @padding-left: 37px;
      padding-left: @padding-left;
      padding-right: @padding-left;
    }
  }



  &__column {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px 35px 36px 37px;
    width: 100%;

    @media @tablet {
      width: 50%;
    }

    &:nth-child(even) {
      display: none;
      padding-left: 9px;

      @media @tablet {
        display: flex;
      }
    }
  }



  &__number {
    color: @color-blue;
    display: block;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 65px;
    text-transform: uppercase;
    transition: color 0.3s ease-out;

    &_spacious {
      margin-bottom: 115px;
      padding-top: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


  a&:hover &__number {
    color: #fff;
  }


  &__title {
    &:extend(.title);
    &:extend(.title.small);
    line-height: (20 / 12);
    margin-bottom: 40px;
    //word-wrap: break-word;
    //word-wrap: normal;

    @media @tablet {
      margin-bottom: 19px;
    }

    &_spacious {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__text {
    line-height: (20 / 14);
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }



  &__read-more-button {
    line-height: 26px;
  }


  &__read-more-text {
    &:extend(.title);
    &:extend(.title.small);
    //display: inline-block;
    display: none;
    line-height: 26px;
    transform: scaleY(0);
    vertical-align: top;
  }


  &__read-more-icon {
    stroke: @color-blue;
      //margin-left 10px
    transition: stroke 0.3s ease-out;
    vertical-align: top;
  }

  a&:hover &__read-more-icon {
    stroke: #fff;
  }
}
