@import "../typography";

div.login-form {
      display: flex;
      width: 100%;
      height: 500px;
      justify-content: center;
      margin-top: 100px;

    .flex-form {
        margin: 10px;

        p {
          margin-bottom: 30px;
        }

        th {
          text-align: left;
        }
    }

    label {
        &:extend(.text);
        font-weight: 400;
    }
  }


