@import "../variables";

.text-columns {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }


  &__column {
    @padding-left: 20px;
    padding-left: @padding-left;
    padding-right: @padding-left;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media @tablet {
      padding: 0 25px 0 32px;
      width: 50%;

      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}
