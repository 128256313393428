@import "../variables";
@import "../mixins";

.popup-wrap {
  background: rgba(0,0,0,0.5);
  display: none;
  height: 100%;
  left: 0;
  min-width: @site-min-width + @site-padding*2;
  //min-width: @site-width + @site-padding*2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}



.popup-item {

  background: #fff;
  display: block;
  padding: 63px 71px 63px 71px;
  position: relative;
  margin: 0 auto;
  width: 545px;


  &__close {
    .img-icon-retina("@{img-dir}i-close.png", "@{img-dir}i-close_2x.png");
    cursor: pointer;
    position: absolute;
    right: 95px;
    transition: transform 0.3s ease-out;
    top: 42px;

    &:hover {
      transform: rotate(270deg);
    }
  }


  &__title {
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__text {
    hyphens: manual;
    margin-bottom: 46px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
