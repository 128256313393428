@import "../variables";
@import "blocks";
@import "../typography";

.slide-wrap {
    &:extend(.block-wrap);
    background: #ffffff;
    border-bottom: 2px solid alpha(@color-blue-dark, 7%);

    &_bg-colored {
        background-color: @color-blue;
    }

    @media @desktop {
        html:not(.tablet) .page-has-animation & {
            border-bottom: none;
        }
    }
}


.slide {
    &:extend(.block);
    @padding-top: 40px;
    padding-top: @padding-top;
    padding-bottom: @padding-top;

    @media @tablet {
        @padding-top: 65px;
        padding-top: @padding-top;
        padding-bottom: @padding-top;
    }

    @media @desktop {
        @padding-top: 40px;
        padding-top: @padding-top;
        padding-bottom: @padding-top;
        min-height: 70vh
    }

    &__title {
        &:extend(.title);
        &:extend(.title.big);
        margin: 0 auto 35px;
        text-align: center;

        &_narrow {
            &:extend(.title.narrow);
        }

        &_x-narrow {
            &:extend(.title.x-narrow);
        }
    }

    &__subtitle {
        &:extend(.title);
        font-size: 20px;
        font-weight: 500;
        margin-top: 40px;
        margin-bottom: 30px;
        text-align: left;
    }

    &__content {
        margin: 0 auto;
        text-align: center;

        &_narrow {
            @media @tablet {
                width: 80%;
            }
            @media @desktop {
                width: 40%;
            }
        }
    }
}

.slide-breadcrumb {
    &:extend(.block);
    @padding-top: 30px;
    padding-top: @padding-top;
    padding-bottom: @padding-top;

    @media @tablet {
        @padding-top: 30px;
        padding-top: @padding-top;
        padding-bottom: @padding-top;
    }

    @media @desktop {
        @padding-top: 30px;
        padding-top: @padding-top;
        padding-bottom: @padding-top;
        min-height: 80vh
    }


    &__title {
        &:extend(.title);
        &:extend(.title.big);
        margin: 0 auto 35px;
        text-align: center;

        &_narrow {
            &:extend(.title.narrow);
        }

        &_x-narrow {
            &:extend(.title.x-narrow);
        }
    }


    &__content {
        margin: 0 auto;
        text-align: center;

        &_narrow {
            @media @tablet {
                width: 80%;
            }
            @media @desktop {
                width: 40%;
            }
        }
    }
}
