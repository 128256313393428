@import "../variables";


.moduler-slide {
//#datakortmodulene {

  @media @desktop {
    padding-bottom: 79px;
  }

  &__title {
    @media @desktop {
      margin-bottom: 76px;
    }
  }

  &__cards {
    margin-bottom: 50px;

    a&:hover {}
  }

  .card__text.text {
    p {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 8;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  .moduler_card_columns {
    display: flex;
    justify-content: space-around;
    flex-direction: row;

    .card__column:first-child {
      justify-content: flex-start !important;
    }

    @media @only-small-mobile {
      flex-direction: column;
    }
    @media @mobile {
        flex-direction: row;
    }
    @media @tablet {
      flex-direction: row;
    }
    @media @desktop {
      flex-direction: row;
    }
  }

    .moduler_card__column {
      width: 50%;

      .svg-icon-module {
        width: 140px;
      }


        @media @only-small-mobile {
          width: 90%;

          &:nth-child(even) {
            padding-top: 10px;
          }
        }
      }
  }

