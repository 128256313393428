@import "../variables";

.button-wrap {
  &_centered {
    text-align: center;
  }
}


.button {
  background: #fff;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  color: #343b54;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  min-width: 110px;
  outline: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  @media tablet {
    width: auto;
  }


  &_block {
    display: block;
    width: 100%;

    @media tablet {
      width: 100%;
    }
  }


  &_gigant {
    border-radius: 40px;
    line-height: 80px;
    min-width: 250px;
  }


  &_big {
    border-radius: 35px;
    line-height: 70px;
    min-width: 250px;
  }


  &_middle {
    border-radius: 35px;
    line-height: 70px;
    min-width: 220px;
  }


  &_dark {
    background-color: alpha(@color-blue-dark, 7%);
  }


  &__icon {
    margin-right: 10px;
    position: relative;
    top: -1px;
  }


  &_dark, &__icon {
    stroke: #cbcece;
    fill: #cbcece;
  }


  &_space-before {
    margin-top: 40px;
  }
}

.dk-okbutton {
  color: @color-blue-medium;
}