@import "../variables";

.how-become-slide-wrap {
  border-bottom: none;
}


.how-become-slide {
  @media @desktop {
    padding-bottom: 151px;
  }


  &__title {
    @media @tablet {
      margin-bottom: 77px;
    }
  }


  &__text-columns {
    margin-bottom: 89px;
    .text-columns__column {
      width: 100%;
    }
  }


  &__cards {}
    //
}

