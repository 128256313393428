@import "../variables";

.purchase-slide-wrap {
  border-bottom: none;
}


.purchase-slide {
  @media @desktop {
    padding-bottom: 160px;
  }


  &__title {
    margin-bottom: 77px;
  }


  &__cards {}
    //
}
