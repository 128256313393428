@import "../variables";
@import "../media";

.header__main-nav-wrap {
    @text-color: white;
    a {
        color: @text-color;
        font-variant: small-caps;
        &:hover, &:active, &:focus {
            text-decoration: underline;
            font-weight: normal;
            outline: none;
        }
        @media @max-tablet {
            height: 1.7em;
        }
    }
}

.small-header-wrap {
    .header__main-nav-wrap {
        a {
            @media @max-desktop {
                height: 1.7em;
            }
        }
    }
}

@media screen and (min-width: 801px) {
    .header__main-nav-wrap {
        left: 0;
        position: absolute;
        bottom: 50px;
        transition: transform 0.3s ease-out;
        z-index: 100;
        background: transparent;
        //color: inherit;
        margin: 0;
        padding: 0;
        text-align: left;
        transform: none;
        width: 100%;

        &.is-opened {
            transform: translateY(0);
        }
    }
    .main-nav {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        @media @desktop {
            align-items: flex-start;
            flex-direction: row;
            margin-bottom: 0;
        }
        @media @tablet {
            align-items: flex-start;
            flex-direction: row;
            margin-bottom: 0;
        }

        &__link {
            //color: inherit;
            font-size: 12px;
            font-weight: 500;
            margin: 0 23px 20px;
            padding: 10px;
            position: relative;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 0.3s ease-out;

            &:last-child {
                margin-bottom: 0;
            }

            @media @desktop {
                padding-bottom: 15px;
                margin-bottom: 0;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &.is-active {
                //color: white;
                //font-weight: bold;
                padding: 10px;
                border: 1px solid white;
                border-radius: 15px;
            }

            &.is-active::after { /* streken under aktivt menyvalg */
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 50%; /* 100% fra toppen er bunnen, 50% fra venstre er midt på. */
                width: 0;
                border-left: 1px solid white; /* strekbredden */
                height: 275px; /* denne strekker seg langt nedenfor horiz linje, men blir kuttet av overflow */
                opacity: 1;
            }

            &:hover {
                //color: white;
                font-weight: bold;

                &:after {
                    background-color: @color-blue-dark;
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) and (min-width: 20px) {
    .header__main-nav-wrap, .main-nav,
    nav.header__main-nav {
        float: right;
        position: relative;
        overflow: hidden;
    }
    .header__main-nav-wrap {
        background: transparent;
        //color: @text-color;
        left: 0;
        margin-left: 0;
        padding-top: 30px;
        position: absolute;
        text-align: center;
        //text-align: right;
        transform: none;
        transition: none;
        width: auto;
        float: right;
        right: 0;

        &.is-opened {
            transform: none;
        }
    }

    .main-nav__link {
        //color: white;
        float: none;
        display: none;
        margin: 0;
        padding: 0;
        border: 0;
        position: relative;
        height: 35px;
        text-align: right;
        text-transform: uppercase;
        transition: none;
        text-decoration: none;

        &.is-active, &:active, &:focus {
            //color: white;
            font-weight: bold;
            padding: 0;
            border: none;
            border-radius: 0;
        }

        &.is-active::after {
            content: none;
            border: none;
        }
    }

    .header__main-nav-wrap a {
        display: none;
    }
    nav.header__main-nav .icn {
        float: right;
        display: inline-block;
        //color: white;
    }

    nav.header__main-nav {
        float: right;
        position: relative;
        display: flex;
        flex-direction: column;
        top: 0;
        height: 150px;
        width: auto;
    }
    nav.header__main-nav a {
        float: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
    }
    nav.header__main-nav .icn a {
        display: block;
        text-align: right;
    }
}

.kontakt-oss {
    position: absolute; top: 0; right: 0;
    z-index: 100;

    a {
        //color: inherit;
        //font-size: 12px;
        //font-weight: 500;
        //padding: 5px 0 10px;
        //position: relative;
        //text-align: center;
        //text-decoration: none;
        //text-transform: uppercase;
        //transition: color 0.3s ease-out;
    }
    //.dohover {
    //    &:hover, &.is-active {
    //        color: white;
    //        font-weight: bold;
    //    }
    //}
}
