@import "../variables";

.how-works-slide-wrap {}
  //


.how-works-slide {
  @media @desktop {
    padding-bottom: 100px;
  }


  &__title {
    margin-bottom: 77px;
  }


  .flex-container {
    display: flex;
    justify-content: space-between;
    width: 940px;

    .intro-card {
      cursor: default;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @media @only-small-mobile {
      flex-direction: column;

      .intro-card {
        width: 100%;
        height: 700px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__intro-cards {
        height: 130vh;
      }
    }

    @media @mobile {
      flex-direction: column;

      .intro-card {
        width: 100%;
        height: 600px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__intro-cards {
        height: 130vh;
      }
    }

    @media @tablet {
      flex-direction: column;

      .intro-card {
        width: 100%;
        height: 500px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__intro-cards {
        height: 1800px;
      }
    }

    @media @desktop {
      flex-direction: row;

      .intro-card {
        width: 300px;
        height: 800px;
        margin: 10px 10px 10px 0;
      }
    }
  }
    //
  .how-works-textbox {
    border: 1px solid @color-blue;
    margin-top: 10px;
    padding: 20px;
  }
}
