@import "../variables";

.map-search-slide-wrap {
  min-height: 60vh;
}
  //


.map-search-slide {
  padding-bottom: 0;
  padding-top: 0;


  &__title {
    margin-bottom: 55px;

    @media @desktop {
      margin-bottom: 76px;
      text-align: center;
    }
  }

  .tctrbutton {
    background-color: @color-blue;
  }
  .map-search-flex {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    //width: 940px;

    //@media @only-small-mobile {
    //  flex-direction: column;
    //}
    //@media @mobile {
    //  flex-direction: column;
    //}
    @media @tablet {
        flex-direction: row;
    }

    @media @desktop {
      flex-direction: row;
    }
  }

  .map-search-item {
    width: 90%;
    margin-right: 20px;

    @media @tablet {
      width: 45%;
    }
    @media @desktop {
      width: 45%;
    }
  }

  .flex-mapimage {
    display: block;
    margin: 0 auto;

    img {
      max-width: 100%;
      height: auto;


    @media @only-small-mobile {
      width: 200px;
    }
    @media @mobile {
      width: 400px;
    }

      @media @tablet {
        width: 300px;
      }
      @media @desktop {
        width: 400px;
      }
    }
  }
}
