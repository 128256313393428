@import "../variables";
@import "../typography";

.intro-cards {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.intro-card {
  background: alpha(@color-blue-dark, 5%);
  color: inherit;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;

  @media @desktop {
    width: 33.333333%;
  }

  &__cover {
    align-items: center;
    border-bottom: 2px solid alpha(@color-blue-dark, 7%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 470px;
    padding: 40px 49px;

    .intro-card:last-child & {
      border-bottom: none;
    }

    @media @desktop {
      border-right: 2px solid alpha(@color-blue-dark, 7%);
      border-bottom: none;

      &:nth-child(3n) &__cover {
        border-right: none;
      }
    }
  }


  &__icon-wrap {
    background: #fff;
    border-radius: 50%;
    display: block;
    flex-shrink: 0;
    @height: 170px;
    height: @height;
    margin: 0 auto 70px;
    position: relative;
    width: @height;
  }

  &__step1 {
    background: url('../images/frustrert_tr.png');
    background-repeat: no-repeat;
    background-size: 180px;
  }

  &__step2 {
    background: url('../images/kurs_tr.png');
    background-repeat: no-repeat;
    background-size: 180px;
  }

  &__step3 {
    background: url('../images/nye-resultat_tr.png');
    background-repeat: no-repeat;
    background-size: 180px;
  }


  &__icon {
    left: 50%;
    position: absolute;
    top: 50%;
    //transform: translate(-50%, -50%);
  }


  &__cover-title {
    &:extend(.title);
    &:extend(.title.small);
    flex-shrink: 0;
    margin-bottom: 20px;
    width: 100%;
  }


  &__cover-more-icon {
    align-self: flex-start;
    @fill: alpha(@color-blue-dark, 21%);
    fill: @fill;
    flex-shrink: 0;
    stroke: @fill;
  }


  &__content {
    align-items: flex-start;
    background: @color-blue;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    top: 0;
    padding: 58px 46px 39px 46px;
    position: absolute;
    left: 0;
    //transform: translateY(100%);
    //transition: transform 0.5s ease-out;
    width: 100%;
    z-index: 1;
  }

  //&:hover &__content {
  //  transform: translateY(0%);
  //}


  &__content-title {
    &:extend(.title);
    &:extend(.title.small);
    flex-shrink: 0;
    margin-bottom: 41px;
    width: 100%;
  }


  &__text {
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 41px;
    overflow: auto;
    width: 100%;
  }


  &__more {
    &:extend(.title);
    &:extend(.title.small);
    color: #fff;
    line-height: 25.5px;
    width: 100%;
  }


  &__text-more-icon {
    @fill: #fff;
    fill: @fill;
    flex-shrink: 0;
    margin-left: 16px;
    stroke: @fill;
    vertical-align: top;
  }
}
