@import "../widgets/blocks";
@import "../typography";

.footer-wrap {
  &:extend(.block-wrap);
  background: #fff;
}


.footer {
  &:extend(.block);
  font-size: 18px;
  font-weight: 300;
  line-height: (30 / 18);
  padding: 60px 20px 0;

  @media @tablet {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 67px 20px;
  }

  @media @desktop {
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    padding-bottom: 10px;

    @media @tablet {
      width: 50%;
    }

    @media @desktop {
      flex-shrink: 0;
      width: 23.5%;
    }
  }

  &__title {
    &:extend(.title);
    &:extend(.title.small);
    margin-bottom: 5px;

    @media @tablet {
      margin-bottom: 5px;
    }

    @media @desktop {
      margin-bottom: 5px;
    }
  }

  &__link {
    color: inherit;
    display: block;
    text-decoration: none;

    &.icon {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-items: center;
      dk-icon {display: block; margin-right: .9ex; width: 1em; margin-top:3px}
      p { line-height: 1.5; }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    margin-bottom: 10px;
  }
}

.footer__legal {
    padding-bottom: 50px;

  .legal-link {
      float: left;
      padding-right: 20px;
  }
}

.small-footer {
  .footer-wrap {
    background-color: #2F95B1;

    .footer__title, .footer__text, .footer__link {
      color: white;
    }
  }
}
