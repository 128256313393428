@import "../variables";
@import "../mixins";
@import "../typography";

// .who-need-slide-wrap {}

.who-need-slide {

  position: relative;

  @media @desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 40px;
  }

  &__card-img {
    @media @desktop {
      .img-icon-retina("@{img-dir}img-card.png", "@{img-dir}img-card_2x.png");
      display: inline-block;
      left: 50%;
      margin-left: -207px;
      margin-top: -233.5px;
      position: absolute;
      top: calc(~'50% + 60px');

      html:not(.tablet) .page-has-animation & {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 60px;

    @media @desktop {
      margin-bottom: 105px;
    }
  }

  &__items {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin-bottom: 44px;
    padding-left: 50px;
    position: relative;

    @media @tablet {
      width: 45%;

      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @media @desktop {
      @width: (220 / 940 * 100%);
      width: @width;

      &:nth-child(2n + 1) {
        margin-right: 100% - @width * 2;
        padding-left: 0;
        padding-right: 50px;
        text-align: right;
      }

      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }

  &__item-title {
    &:extend(.title);
    &:extend(.title.small);
    margin-bottom: 30px;
    position: relative;
    margin-top: 12px;
  }

  &__item-icon-wrap {
    position: absolute;
    left: -57px;
    top: -14px;

    @media @desktop {
      right: -57px;
    }
  }

  @media @desktop {
    &__item:nth-child(2n) &__item-icon-wrap {
      left: -57px;
      right: auto;
    }
  }

  &__icon {
  }
  //

  &__item-text {
  }

  .need-item {
    width: 100%;
    border: 1px solid #8fd8ee;
    flex: 1;
    padding: 20px;

    @media @only-small-mobile {
      margin-bottom: 20px;
    }
    @media @mobile {
      margin-bottom: 20px;
    }

    @media @tablet {
      width: 33%;
      margin: 20px;
    }
    @media @desktop {
      width: 33%;
      margin: 20px;
    }
    img {
      max-width: 100%;
      height: auto;

      @media @tablet {
        margin: 0 auto;
      }
      @media @desktop {
        margin: 0 auto;
      }
    }
  }

  .flex-mapimage {
    display: block;
    //margin: 0 auto;
    border: none;
    padding: 0;

    img {
      max-width: 100%;
      height: auto;

    @media @tablet {
      margin: 0 auto;
    }
    @media @desktop {
      margin: 0 auto;
    }
    }
  }

  .first-item {
    @media @only-small-mobile {
      margin-top: 0;
    }
    @media @mobile {
      margin-top: 0;
    }
    @media @tablet {
      width: 33%;
      margin: 20px;
    }
    @media @desktop {
      width: 33%;
      margin: 20px;
    }
  }
  .need-slide-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;

    @media @only-small-mobile {
      margin-bottom: 0;
    }
    @media @mobile {
      margin-bottom: 0;
    }
    @media @tablet {
      flex-direction: row;
    }

    @media @desktop {
      flex-direction: row;
    }

  }

  .need-item-invisible {
    width: 33%;
    flex: 1;
    margin: 0;
    padding: 0;

    @media @tablet {
      margin: 20px;
      padding: 20px;
    }

    @media @desktop {
      margin: 20px;
      padding: 20px;
    }
  }

  .icon_who {
    float: left;
  }
  //
}