@import "../variables";

.advantages-test-center-slide-wrap {}
  //


.advantages-test-center-slide {
  @media @desktop {
    //padding-bottom: 204px;
  }


  &__title {
    margin-bottom: 50px;
  }


  &__showcase {}
    //
}

.showcase-area {
    margin-top: 100px;
}

.showcase-area-title {
  font-weight: normal;
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

.showcase-section {
  margin-top: 20px;
}