@import "../variables";

.training-slide-wrap {}
  //


.training-slide {

  @media @desktop {
    padding-bottom: 79px;
  }

  &__title {
    @media @desktop {
      margin-bottom: 76px;
    }
  }

  &__cards {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  .card__text.text {
    p {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 8;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  .training_card_columns {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    background-color: #8fd8ee;
    border: none;
    padding: 20px;

    .card__column:first-child {
      justify-content: flex-start !important;
    }

    &:hover {
    }

    @media @only-small-mobile {
      flex-direction: column;
    }
    @media @mobile {
      flex-direction: row;
    }
    @media @tablet {
      flex-direction: row;
    }
    @media @desktop {
      flex-direction: row;
    }
  }

    .training_card__column {
        width: 30%;
        padding: 10px;
    }
    .training_card__text_column {
        width: 60%;
        padding: 10px;
    }

   .training_card__column > img {
       height: 100px;
       width: 100px;
       color: #30393a;
   }
    .card__title {
        padding-bottom: 10px;
    }

  .card__link {
    color: @color-blue-dark;
  }

}