@import "../variables";
@import "../mixins";

.logo {
  .img-icon-retina("@{img-dir}datakortet-logo.svg", "@{img-dir}datakortet-logo.svg");
  display: inline-block;
  text-decoration: none;
}

.inverse-logo {
  .img-icon-retina("@{img-dir}datakortet-logo-white.svg", "@{img-dir}datakortet-logo-white.svg");
  display: inline-block;
  text-decoration: none;
}

.ecdl-logo {
  .img-icon-retina("@{img-dir}icdl-logo-small.png", "@{img-dir}icdl-logo-small.png");
  display: inline-block;
  text-decoration: none;
}

.dk-ecdl-logo {
  position: absolute;
  left: 0; top: 35px;
  height: 140px; width: 140px;
  z-index: 1;

  .inverse-logo {
    display: block;
    width: 126px; height: 74.3px;
  }
  .ecdl-logo {
    display: block;
    position: absolute;
    bottom: 5px;
  }
}