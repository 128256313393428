@import "../variables";

.advantages-slide-wrap {
  min-height: 60vh;
}
  //


.advantages-slide {
  min-height: 60vh;
  @media @desktop {
    //padding-bottom: 100px;
  }


  &__title {
    @media @desktop {
      margin-bottom: 77px;
    }
  }


  &__showcase {

    @media @only-small-mobile {
      flex-direction: column;
    }
    @media @mobile {
      flex-direction: column;
    }
    @media @tablet {
      flex-direction: row;
    }
    @media @desktop {
      flex-direction: row;
    }


    .showcase__item {

      @media @only-small-mobile {
        width: 100%;
      }
      @media @mobile {
        width: 100%;
      }
    @media @tablet {
      width: 33%;
      margin-right: 0;
    }
    @media @desktop {
      width: 33%;
      margin-right: 0;
    }
    }
  }
    //
}
