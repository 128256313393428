@import "../variables";
@import "../mixins";

.lets-get-slide-wrap {
  border-bottom: none;
  position: relative;

  @media @desktop {
    min-height: 70vh;
  }

  &__bg-img {
    .img-icon-retina("@{img-dir}bg-card-with-icons.png", "@{img-dir}bg-card-with-icons_2x.png");
    display: inline-block;
    height: 420px;
    right: 0;
    position: absolute;
    top: 0;
    width: 416px;

    @media @desktop {
      height: 654px;
      width: 645px;
    }
  }
}



.lets-get-slide {
  padding-top: 450px;


  @media @desktop {
    min-height: 707px;
    padding-top: 202px;
  }


  &__content-wrap {
    position: relative;

    @media @tablet {
      width: 47%;
    }
  }



  &__title {
    text-align: left;
  }


  &__content {
    margin: 0 0 42px;
    text-align: left;
  }
}
