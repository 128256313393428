@import "variables";
@import "mixins";

.icon {

  background: center / cover no-repeat;
  display: inline-block;

  &_close {
    .img-icon-retina("@{img-dir}logo.png", "@{img-dir}logo_2x.png");
    .img-icon-retina("@{img-dir}i-close.png", "@{img-dir}i-close_2x.png");
  }

  &_close-2 {
    .img-icon-retina("@{img-dir}i-close-2.png", "@{img-dir}i-close-2_2x.png");
  }

  &_menu {
    .img-icon-retina("@{img-dir}i-menu.png", "@{img-dir}i-menu_2x.png");
  }

  &_chevron {
    .img-icon-retina("@{img-dir}i-chevron.png", "@{img-dir}i-chevron_2x.png");
  }

  &_clipboard-1 {
    .img-icon-retina("@{img-dir}i-clipboard-1.png", "@{img-dir}i-clipboard-1_2x.png");
  }

  &_clipboard-2 {
    .img-icon-retina("@{img-dir}i-clipboard-2.png", "@{img-dir}i-clipboard-2_2x.png");
  }

  &_clipboard-3 {
    .img-icon-retina("@{img-dir}i-clipboard-3.png", "@{img-dir}i-clipboard-3_2x.png");
  }

  &_login {
    .img-icon-retina("@{img-dir}i-login.png", "@{img-dir}i-login_2x.png");
  }


  &_advantages-1 {
    .img-icon-retina("@{img-dir}i-advantages-1.png", "@{img-dir}i-advantages-1_2x.png");
  }
  &_advantages-2 {
    .img-icon-retina("@{img-dir}i-advantages-2.png", "@{img-dir}i-advantages-2_2x.png");
  }
  &_advantages-3 {
    .img-icon-retina("@{img-dir}i-advantages-3.png", "@{img-dir}i-advantages-3_2x.png");
  }
  &_advantages-4 {
    .img-icon-retina("@{img-dir}i-advantages-4.png", "@{img-dir}i-advantages-4_2x.png");
  }
  &_advantages-5 {
    .img-icon-retina("@{img-dir}i-advantages-5.png", "@{img-dir}i-advantages-5_2x.png");
  }
  &_advantages-6 {
    .img-icon-retina("@{img-dir}i-advantages-6.png", "@{img-dir}i-advantages-6_2x.png");
  }
  &_advantages-7 {
    .img-icon-retina("@{img-dir}i-advantages-7.png", "@{img-dir}i-advantages-7_2x.png");
  }
  &_advantages-8 {
    .img-icon-retina("@{img-dir}i-advantages-8.png", "@{img-dir}i-advantages-8_2x.png");
  }
  &_advantages-9 {
    .img-icon-retina("@{img-dir}i-advantages-9.png", "@{img-dir}i-advantages-9_2x.png");
  }


  &_who-1 {
    .img-icon-retina("@{img-dir}i-who-1.png", "@{img-dir}i-who-1_2x.png");
  }
  &_who-2 {
    .img-icon-retina("@{img-dir}i-who-2.png", "@{img-dir}i-who-2_2x.png");
  }
  &_who-3 {
    .img-icon-retina("@{img-dir}i-who-3.png", "@{img-dir}i-who-3_2x.png");
  }
  &_who-4 {
    .img-icon-retina("@{img-dir}i-who-4.png", "@{img-dir}i-who-4_2x.png");
  }



  &_gloppen {
    .img-icon-retina("@{img-dir}logo_gloppen.jpg", "@{img-dir}logo_gloppen.jpg");
    height: 90px;
    width: 90px;
  }

  &_svk {
    .img-icon-retina("@{img-dir}logo_svk.png", "@{img-dir}logo_svk.png");
    height: 90px;
    width: 90px;
  }

  // &_eika {
  //   .img-icon-retina("@{img-dir}logo_eika.png", "@{img-dir}logo_eika.png");
  //   width: 90px;
  // }

  // &_ofr {
  //   .img-icon-retina("@{img-dir}logo_ofr.png", "@{img-dir}logo_ofr.png");
  //   width: 90px;
  // }

  // &_sb1o {
  //   .img-icon-retina("@{img-dir}logo_sb1o.png", "@{img-dir}logo_sb1o.png");
  //   width: 90px;
  // }

  // &_nibio {
  //   .img-icon-retina("@{img-dir}logo_nibio.png", "@{img-dir}logo_nibio.png");
  //   width: 90px;
  // }


  &_hydro {
    .img-icon-retina("@{img-dir}hydro_logo.jpg", "@{img-dir}hydro_logo.jpg");
    height: 120px;
    width: 120px;
  }

  &_sintef {
    .img-icon-retina("@{img-dir}sintef_logo.jpg", "@{img-dir}sintef_logo.jpg");
    height: 80px;
    width: 80px;
  }

  &_vofo {
    .img-icon-retina("@{img-dir}vofo-eple-logo.jpg", "@{img-dir}vofo-eple-logo.jpg");
    height: 90px;
    width: 90px;
  }

  &_telenor {
    .img-icon-retina("@{img-dir}telenor_logo.jpg", "@{img-dir}telenor_logo.jpg");
    height: 90px;
    width: 90px;
  }

  &_svi {
    .img-icon-retina("@{img-dir}logo_svinvest.png", "@{img-dir}logo_svinvest.png");
    height: 90px;
    width: 90px;
  }

  &_dnd {
    .img-icon-retina("@{img-dir}logo_dnd.png", "@{img-dir}logo_dnd.png");
    height: 90px;
    width: 90px;
  }

  &_moduler {
    .img-icon-retina("@{img-dir}moduler.png", "@{img-dir}moduler.png");
    height: 400px;
    width: 400px;
  }
}
