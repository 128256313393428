@import "../variables";

.dk-team-members {
    position: relative;

    a {
        color: @color-blue-dark;
        text-decoration: none;
    }

    //.dk-icon-envelope.dk-small {
    //    background-image: url("@{icon-dir}24.envelope.svg");
    //    width: 30px;
    //    height: 30px;
    //}

}

.dk-team-members-list {
    padding: 40px 10px 0;
    position: relative;
    transform: translateX(0);
    transition: transform 0.3s ease;
    white-space: nowrap;
    z-index: 0;
}
.dk-team-members-list-item {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 30px;
    vertical-align: top;
    &:last-child {
        padding-right: 0;
    }

    @media @only-small-mobile {
      width: 90%;
      margin: 20px;
    }
    @media @mobile {
      width: 40%;
      margin: 20px;
    }
    @media @tablet {
      width: 20%;
      margin: 10px;
    }
    @media @desktop {
      width: 18%;
      margin: 5px;
    }
}
.dk-team-member-tile {
    background-color: #fff;
    display: block;
    position: relative;
    &:hover {
        .dk-team-member-photo {
            opacity: 0.2;
        }
        .dk-team-member-contact {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
.dk-team-member-photo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 220px;
    overflow: hidden;
    position: relative;
    transition: opacity 0.6s ease;
    width: 165px;
    &:after {
        background-image: inherit;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.dk-team-member-contact {
    background-color: #8fd8ee;
    border-radius: 60px;
    height: 60px;
    left: 57%;
    position: absolute;
    top: 110px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translate(-50%, -50%) scale(0);
    width: 60px;
    .nt-icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.dk-team-member-info {
    text-align: left;
    padding-left: 4px;
}
.dk-team-member-name {
    font-size: 1rem;
    padding-top: 8px;
}
.dk-team-member-role {
    font-size: smaller;
    font-style: italic;
}
@media screen and (max-width: 1130px) {
    .dk-team-members-list {
        padding: 40px 0 0;
    }
    .dk-team-member-photo {
        width: auto;
    }
}
