@import "../variables";
@import "../widgets/blocks";
@import "../media";

.header-wrap {
    &:extend(.block-wrap);
    background: @color-blue-medium;
    overflow: hidden; // need to hide horizontal scroll by intro slide glare

    display: flex;
    flex-direction: column;
    min-height: 30vh;
}

.small-header-wrap {
    &:extend(.block-wrap);
    &>.header { border-bottom: none;}

    background: #2f95b1;
    overflow: hidden; // need to hide horizontal scroll by intro slide glare
    display: flex;
    flex-direction: column;
    height: 18vh;
    min-height: 180px;

    @media @max-desktop {
        height: 11vh;
        min-height: 140px;
        .ecdl-logo {display: none;}
    }
    @media @max-tablet {

    }
}


.kontakt-oss {
    a {
        font-variant: small-caps;
        margin-right: 4px;
    }
    a:hover {
        font-weight: normal;
    }
}


.header {
    &:extend(.block);
    align-items: flex-start;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 31px 0;
    position: relative;
    z-index: 20;
    height: 180px;
    border-bottom: 1px solid white;
    overflow: hidden;

    @media @desktop {
        display: block;
        padding: 44px 30px 40px;
    }
    @media @tablet {
        display: block;
        padding: 44px 30px 40px;
    }

    &__logo {
        left: 0;
        position: absolute;
        top: 30px;
        height: 80px;
        width: 120px;
    }

    .ecdl__logo {
        float: right;
        left: 0;
        position: absolute;
        top: 110px;
        height: 80px;
        width: 100px;
    }

    .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
