.tctr-lukket {
    margin-top: 50px;

   .flex-container {
      display: flex;
      width: 100%;
      flex-direction: row;

      @media screen and (max-width: 850px) and (min-width: 20px) {
         flex-direction: column;
      }

   }
   .flex-links {
        width: 60%;
        margin-right: 60px;
        @media screen and (max-width: 850px) and (min-width: 20px) {
            width: 90%;

         }
    }
   .flex-img {
        width: 30%;
        @media screen and (max-width: 850px) and (min-width: 20px) {
            width: 90%;
            margin-top: 40px;
         }
    }
}

.lukket-slide {
   padding-top: 20px;
   min-height: 60vh;

   .tctr-meny {
      margin-bottom: 60px;
   }
   .tctr-meny > ul {
      list-style-type: none;
      padding: 0;
      overflow: hidden;
   }
   .tctr-meny > ul > li {
      float: left;
      margin-right: 40px;
   }

   .tctr-meny > ul > li:last-child {
      margin-right: 0;
   }

   .tctr-meny > ul > li > a:hover {
      font-weight: 800;
   }

   .tctr-meny > ul > li a {
      display: block;
      color: black;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
   }
}

