@import "../variables";
@import "../typography";

.tab {

  &__navs {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;

    &_bordered {
      border-bottom: 2px solid alpha(@color-blue-dark, 12%);
      justify-content: space-between;
    }
  }

  &__nav {
    &:extend(.title);
    &:extend(.title.small);
    color: @color-blue;
    cursor: pointer;
    display: inline-block;
    margin: 0 29px;
    padding: 5px 0 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s ease-out;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:after {
      background-color: @color-blue;
      bottom: 0;
      content: '';
      display: inline-block;
      height: 2px;
      left: 50%;
      margin-left: -12.5px;
      opacity: 1;
      position: absolute;
      transition: background 0.3s ease-out, opacity 0.3s ease-out;
      width: 25px;
    }

    &_grey {
      color: alpha(@color-blue-dark, 49%);

      &:after {
        background-color: alpha(@color-blue-dark, 49%);
      }
    }


    &:hover,
    &.is-active {
      color: @color-blue-dark;

      &:after {
        background-color: @color-blue-dark;
        opacity: 0;
      }
    }


    &_bordered {
      padding-bottom: 26px;

      &:after {
        background-color: transparent;
        bottom: -2px;
        left: 0;
        margin-left: 0;
        width: 100%;
      }

      &:hover,
      &.is-active,
      &:after {
        background-color: @color-blue-dark;
        opacity: 1;
      }
    }
  }

  &__panel {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
