.scroll-button {
  color: inherit;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0 46px;
  position: relative;
  text-transform: uppercase;

  &__icon {
    bottom: 0;
    height: 25px;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    stroke: currentColor;
    transform: rotate(90deg);
    width: 26px;
  }
}
