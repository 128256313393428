.svg-icon {
  &_plus {
    height: 25.5px;
    width: 25.5px;
  }

  &_arrow {
    height: 25.5px;
    width: 25.5px;
  }

  //&-module {
  //  height: 120px;
  //  width: 120px;
  //}
}

