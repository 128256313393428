@import "variables";

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {font: @text-font;}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffffff;
    color: @text-color;
    font-size: 1rem;
    position: relative;
}

button, input, optgroup, select, textarea {
    font: @text-font;
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

textarea {
    resize: vertical;
}

::selection {
    background: @highlight-bg-color;
    color: @highlight-color;
    text-shadow: none;
}


a {
    color: @color-blue-medium;
}

.breadcrumb {
    list-style: none;
    padding-left: 0;
    padding-bottom: 30px;

    li {
        display: inline;
    }

    li + li:before {
        padding: 5px;
        content: "/\00a0";
    }
}
