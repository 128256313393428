@import "../variables";

.flexbox {
  display: flex;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }

  @media screen and (min-width: 750px) {
    flex-direction: row;
  }

  .fTable td.error {
    padding-bottom: 20px;
    color: red;
  }

  .fTable tfoot th {
    padding-top: 24px;
  }

  .hints {
    float: right;
    border: 1px solid #ccc;
    padding: 20px;
    width: 45%;
  }

  .ekbform {
    margin: 40px 20px;
    width: 45%;
  }
}