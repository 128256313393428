@import "../variables";

.block-wrap {
  margin: 0 auto;
    //min-width site-width + site-padding*2
  min-width: @site-min-width;
  padding-left: @site-padding;
  padding-right: @site-padding;
  width: 100%;
}


.block {
  margin: 0 auto;
  max-width: @site-max-width;
  width: 100%;
}

