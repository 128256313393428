@import "../variables";
@import "../widgets/blocks";

.copyright-line {
  &:extend(.block);
  align-items: flex-start;
  color: alpha(@color-blue-dark, 57%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 20px 20px;

  @media @tablet {
    flex-direction: row;
  }

  @media @desktop {
    padding-left: 0;
    padding-right: 0;
  }


  &__item {}
    //


  &__link {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }

  &:hover {
    color: @color-blue-dark;
  }
}
