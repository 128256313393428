@import "../variables";
@import "../typography";

@l-form-width: 320px;


.map-search-wrap {
  position: relative;

  #t {
    width: 90%;
  }
  #acsearch {
    margin-left: 10px;
  }

  @media screen and (max-width: 750px) {

  }
}


.map-search {
  padding: 0;


  &__form-wrap {
    //padding: 60px 0;
    width: 100%;

    @media @tablet {
      //padding: 62px 20px 89px 0;
      //width: @l-form-width;
    }

    @media @desktop {
      //padding: 62px 60px 89px 0;
    }

    .map-flex {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media screen and (max-width: 750px) {
        flex-direction: column;

        .flex-item-map {
        width: 90%;
        height: auto;
        margin: 80px 10px 10px;
      }
      }

      #map-canvas {
        height: 450px;
        width: 100%;
      }

      .flex-item-info {
        width: 300px;
        height: auto;
        margin: 10px;

      .gen-info {
        margin-top: 80px;
      }
      .restable {
        margin-top: 80px;
      }

      hr {
        //color: @color-blue;
        border-top: 1px solid @color-blue;
      }
  }
      .flex-item-map {
        width: 600px;
        height: 600px;
        margin: 80px 10px 10px;

        @media screen and (max-width: 750px) {

        width: 90%;
        height: auto;
        margin: 80px 10px 10px;
      }
      }
    }
  }

  .tctrinfo {
    border: 1px solid @color-blue;
    padding: 10px;
  }


  &__form {}
    //


  &__input {
    margin-bottom: 41px;
  }


  &__list {
    list-style: none;
    margin: 0 0 42px;
    padding: 0;
  }


  &__list-item {
    &:extend(.title);
    &:extend(.title.small);
    line-height: 25px;
    margin-bottom: 25px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__list-badge {
    background: @color-blue;
    border-radius: 50%;
    color: #fff;
    font-style: normal;
    @height: 25px;
    height: @height;
    line-height: @height;
    min-width: @height;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }


  &__map-wrap {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;

    @media @tablet {
      display: block;
      left: @l-form-width + @site-padding;
      width: calc(~"100% -" @l-form-width + @site-padding);
    }

    @media @desktop {
      left: calc(~"50% -" @site-max-width / 2 - @l-form-width);
      width: calc(~"100% -" @site-max-width / 2 + 620px);
    }
  }


  &__map {
    background: #f2efe9;
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
  }
}
