@import "variables";

h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h1 {font-size: 2.25rem;}
h2 {font-size: 2rem;}
h3 {font-size: 1.75rem;}
h4 {font-size: 1.5rem;}
h5 {font-size: 1.25rem;}
h6 {font-size: 1rem;}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child {
    margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol:last-child, ul:last-child, dl:last-child {
    margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

img {
    height: auto;
    max-width: 100%;
}

.title {
    font-weight: normal;
    margin: 0;

    &.big {
        font-size: 40px;
        font-weight: 200;
        line-height: 1.2;

        @media @desktop {
            font-size: 50px;
        }
    }

    &.small {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &.narrow {
        @media @tablet {
            width: 60%;
        }
    }

    &.x-narrow {
        @media @tablet {
            width: 45%;
        }
    }
}


a {
    color: @color-blue-medium;

    &:focus, &:hover {
        color: @color-blue-dark;
        text-decoration: none;
    }

    &:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

.dark-background {
    a {
        color: @text-white;
        &:focus, &:hover {
            color: @color-blue-dark;
        }
    }
}

.text {
    font-weight: 300;
    hyphens: auto;
    line-height: (20 / 14);

    &_spacious {
        font-size: 16px;
        line-height: (30 / 16);
    }

    &_large {
        font-size: 20px;
        line-height: (30 / 20);
    }
}
