@import "../variables";
@import "../typography";

.showcase {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    @media @tablet {
      margin-bottom: 40px;
      padding-left: 10px;
      padding-right: 10px;
      width: 50%;

      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @media @desktop {
      @item-width: (222 / 940 * 100%); // proportions from the design
      item-width: @item-width;
      margin-bottom: 45px;
      margin-right: ((100% - @item-width * 4) / 3);
      padding: 0;
      width: @item-width;


      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3),
      &:nth-last-child(4) {
        margin-bottom: 0;
      }
    }
  }

  &_five-in-line &__item {
    width: 50%;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    @media @tablet {
      width: 33.333333%;

      &:nth-last-child(3) {
        margin-bottom: 0;
      }
    }

    @media @desktop {
      margin-right: 0;
      width: 20%;

      &:nth-last-child(5) {
        margin-bottom: 0;
      }
    }
  }


  &__icon-wrap {
    border: 2px solid @color-blue;
    border-radius: 50%;
    display: inline-block;
    height: 90px;
    margin-bottom: 33px;
    position: relative;
    width: 90px;

    &_no-border {
      border: none;
    }
  }


  &__icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }


  &__title {
    &:extend(.title);
    &:extend(.title.small);
    margin-bottom: 21px;
  }


  &__text {
    @padding-left: 25px;
    padding-left: @padding-left;
    padding-right: @padding-left;
  }
}
