@import "../variables";

.our-clients-slide-wrap {
  border-bottom: none;
}


.our-clients-slide {

  @media @desktop {
    padding-bottom: 50px;
  }

  &__title {
  }
  //

  &__content {
    margin-bottom: 72px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__showcase {
  }
  //
}