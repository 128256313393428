@import "../variables";

.user-button {
  background: #fff;
  border-radius: 25px;
  cursor: pointer;
  color: #000;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  padding: 0 27px 0 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;


  &__avatar {
    background: center / cover no-repeat @color-blue-dark;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 20px;
  }


  &__icon {
    display: inline-block;
    margin-right: 12px;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }


  &__name {
    display: inline-block;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
  }


  &__arrow {
    margin-left: 10px;
    margin-right: -10px;
  }
}

.logout-button {
  background-color: @color-blue-medium;
  float: right;
  margin-top: 15px;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: @color-blue-dark;
    }
  }

}
