.regtable{
    width: 100%;
    border-collapse: collapse;
    caption-side: bottom;

    caption {
      font-weight: bold;
      text-align: right;
    }

    table, td, th {
      border: 1px solid black;
    }

    td, th {
      padding: 3px;
    }

    th {
      font-weight: bold;
      color: #fafafa;
      background: #666;
      text-align: left;
    }

    .example {
      padding: 3px;
      width: 80%;
      text-align: center;
      margin: .5ex auto 1ex;
      font-weight: bold;
      border: 1px solid #666;
      background: #ddd;

      span {
        background: #fafafa;
        padding: 3px;
      }
    }
}

